import React from "react";
import Field from "./field.js";

const DateField = (props) => {
  const validationFunction = (value) => {
    if (value.trim() === "") {
      return false;
    }
    return true;
  };
  return (<Field {...props} type="date" validationFunction={ validationFunction } />);
};

export default DateField;
